<template>
  <v-container fluid class="py-0">
    <v-form ref="form">
      <v-select v-if="gender.shared == true"
        v-model="gender.answer"
        :items="gender.options"
        :label="gender.title"
        :disabled="gender.disabled"
        item-text="name"
        item-value="key"
        :rules="[value => (value != null && value != undefined) || gender.required_message ] "
      ></v-select>

      <v-select v-if="maritalStatus.shared == true"
        v-model="maritalStatus.answer"
        :items="maritalStatus.options"
        :label="maritalStatus.title"
        item-text="name"
        item-value="key"
        :rules="[value => (value != null && value != undefined) || maritalStatus.required_message ] "
      ></v-select>

      <v-menu
        ref="dob.tempAnswer"
        v-model="dob.tempAnswer"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :disabled="dob.disabled"
            v-model="dob.answer"
            :label="dob.title"
            required
            clearable
            readonly
            v-on="on"
            v-bind="attrs"
            :rules="[value => (value != null && value != undefined) || dob.required_message ] "
          ></v-text-field>
        </template>
        <v-date-picker
          ref="picker"
          v-model="dob.answer"
          :max="new Date().toISOString().substr(0, 10)"
          @input="dob.tempAnswer = false"
          @blur="date = parseDate(dateFormatted)"
        ></v-date-picker>
      </v-menu>

      <v-autocomplete
        :disabled="country.disabled"
        v-model="country.answer"
        :items="country.options"
        :label="country.title"
        item-text="title"
        item-value="title"
        :rules="[ value => (value != null && value != undefined) || country.required_message ]"
      ></v-autocomplete>

        <v-autocomplete
          v-if="showRegion"
          v-model="region.answer"
          :items="region.options"
          :label="region.title"
          item-value="region.answer"
          item-text="region.answer"
        >
        </v-autocomplete>

      <v-row class="pb-2">
        <v-btn text @click="$router.push({ path: '/demographics/additional' });" color="primary">{{ $t('additional_demographic') }}</v-btn>
      </v-row>

      <v-row class="pl-3">
        <v-btn @click="updateDemographics" color="primary">{{ $t('update') }}</v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { DataService } from "./../../services/DataService";

const dataService = new DataService();

export default {
  data() {
    return {
      demographics: this.$store.state.userProfile.demographics,
      keysMapping: {
        "Gender": "gender",
        "Date Of Birth": "dob",
        "Marital Status": "maritalStatus",
        "Country": "country",
        "Region": "region"
      },
      gender: {
        title: this.$t('gender'),
        required_message: `${this.$t("gender")} ${this.$t('required')}`,
        answer: null,
        shared: true,
        disabled: false,
        questionType: "dropdown",
        options: [
          {
            key: "Male",
            name: this.$t("male")
          },
          {
            key: "Female",
            name: this.$t("female")
          }
        ],
      },
      maritalStatus: {
        title: this.$t('marital_status'),
        required_message: `${this.$t("marital_status")} ${this.$t("required")}`,
        answer: null,
        shared: true,
        questionType: "dropdown",
        options: [
          {
            key: "Single",
            name: this.$t("single")
          },
          {
            key: "Married",
            name: this.$t("married")
          },
          {
            key: "Divorced",
            name: this.$t("divorced")
          }
        ]
      },
      dob: {
        title: this.$t('date_of_birth'),
        required_message: `${this.$t("date_of_birth")} ${this.$t("required")}`,
        answer: null,
        shared: true,
        questionType: "date",
        disabled: false,
        dateMenu: false,
        tempAnswer: false,
      },
      country: {
        title: this.$t('country'),
        required_message: `${this.$t('country')} ${this.$t('required')}`,
        answer: null,
        shared: true,
        disabled: true,
        options: []
      },
      region: {
        title: '',
        answer: null,
        options: [],
        shared: true
      },
      showRegion: false,
    }
  },
  mounted() {
    this.$store.state.loader = true
    this.getRegion()
    dataService.getConfig("countries").then(data => {
      this.country.options = data
      this.$store.state.loader = false
    })

    for (let d of this.demographics) {
      let localObject = this[this.keysMapping[d.title]]

      localObject.answer = d.answer
      localObject.shared = d.shared

      if (d.title == 'Date Of Birth' && d.answer != null) {
        localObject.disabled = true
      } 

      if (d.title == 'Gender' && d.answer != null) {
        localObject.disabled = true
      }
    }
  },
  methods: {

    async getRegion() {
      this.showRegion = false
      let country = this.$commonProcessor.userCountry(this.$store.state.userProfile)
      await dataService.getRegions(country).then((res => {
        if (res.data && res.data.regions) {
          this.region.options = res.data.regions
          this.region.title = res.data.label
          this.showRegion = true
        }
      }))
    },


    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    updateDemographics() {
      if (this.$refs.form.validate()) {
        this.$store.state.loader = true

        let toUploadDemographics = []

        for(let key of Object.keys(this.keysMapping)) {
          let localObject = this[this.keysMapping[key]]

          toUploadDemographics.push({
            title: key,
            answer: localObject.shared == true ? localObject.answer : null,
            shared: localObject.shared
          })
        }

        dataService.updateMobileUser(this.$store.state.token, {
          userId: this.$store.state.userProfile._id,
          demographics: toUploadDemographics
        }).then(() => {
          this.dob.disabled = true
          this.gender.disabled = true
          this.$store.state.snackBarText = this.$t('successfully_updated')
          this.$store.state.showSnackBar = true
          this.$store.state.loader = false
        })
      }
    }
  },
  watch: {
    "dob.tempAnswer"(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    }
  }
};
</script>